<template>
    <div class="stationManage">
        <h2 class="title-page">岗位管理</h2>
        <div class="content-station">
            <!-- 顶部 -->
            <div class="station-flex station-info">
                <el-input placeholder="请输入岗位名称" v-model="name" class="name-input">
                    <el-button slot="append" icon="el-icon-search" @click="getStationList"></el-button>
                </el-input>
                <el-button type="primary" icon="el-icon-plus" @click="toAddStation">新增岗位</el-button>
            </div>
            <!-- 表格 -->
            <el-table :data="tableData" style="width: 100%"
                      :header-cell-style="{'background-color':'rgba(175, 192, 207, 0.2)'}">
                <el-table-column prop="name" label="品牌名"></el-table-column>
                <el-table-column prop="station_user_count" label="关联员工数"></el-table-column>
                <el-table-column prop="created_at" label="新增时间"></el-table-column>
                <el-table-column prop="updated_at" label="编辑时间"></el-table-column>
                <el-table-column prop="created_by" label="创建人"></el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button type="text" @click="editStation(scope.row)">编辑</el-button>
                        <el-button type="text" @click="delStation(scope.row.id)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <!-- 新增岗位 -->
        <el-dialog :title="dialogType==1?'新增岗位':'编辑岗位'" :visible.sync="showDialog" width="680px" :show-close="false"
                   :before-close="closeDialog">
            <div class="station-flex">
                <span>岗位名称</span>
                <el-input placeholder="请输入岗位名称" v-model="stationName" class="station-input" maxlength="8"></el-input>
            </div>
            <p class="gl-p">关联员工</p>
            <div class="bdr-yg station-flex">
                <div class="bdr-left">
                    <el-input placeholder="请输入员工姓名" v-model="staffName">
                        <el-button slot="append" icon="el-icon-search" @click="showFocus()"></el-button>
                    </el-input>
                    <p class="left-yg">员工</p>
                    <el-tree
                        ref='staffTree'
                        :data="treeData"
                        show-checkbox
                        node-key="id"
                        :default-expanded-keys="expanded"
                        :default-checked-keys="[]"
                        :props="defaultProps"
                        @check="treeCheck">
                    </el-tree>
                </div>
                <div class="bdr-right">
                    <p class="check-title">已选员工</p>
                    <div class="grid-check">
                        <div class="" v-for="(item,index) in checkStaff" :key="item.id">
                            <span>{{ item.name }}</span>
                            <i class="el-icon-error" @click="removeCheck(index)"></i>
                        </div>
                    </div>
                </div>
            </div>
            <div slot="footer">
                <el-button @click="closeDialog" class="close-btn">取 消</el-button>
                <el-button type="primary" @click="addQeury">保 存</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import {
    delAddStationInfo,
    editAddStationInfo,
    getAddStationInfo,
    saveAddStationInfo,
    stationList
} from "../../../api/srv-store";

export default {
    name: 'stationManage',
    data() {
        return {
            name: '',
            staffName: '',
            checkBrand: '',
            checkCompany: '',
            brandList: [],
            companyList: [],
            tableData: [],
            showDialog: false,
            dialogType: 1, //1新增，2编辑
            stationName: '',
            treeData: [],
            defaultProps: {
                children: 'children',
                label: 'name',
                disabled: 'is_true'
            },
            checkStaff: [],//所选择的员工
            expanded: [],
            stationId: ''
        }
    },
    watch: {
        treeData() {
            if (this.dialogType === 2) {
                let staff = [];
                let treeDate = JSON.parse(JSON.stringify(this.treeData))
                let newArr = this.treeConvertToArr(treeDate)
                newArr.some(item => {
                    if (item.is_true === true) {
                        staff.push(item)
                    }
                })
                this.checkStaff = staff
                this.$nextTick(() => {
                    this.$refs.staffTree.setCheckedNodes(staff)
                })
                staff.map(item => {
                    this.expanded.push(item.parentid)
                })
            }
        }
    },
    created() {
        this.checkBrand = JSON.parse(localStorage.getItem('brandId'))
        this.checkCompany = JSON.parse(localStorage.getItem('storesId'))
        this.getStationList()
    },
    methods: {
        //获取岗位列表
        getStationList() {
            stationList({
                brand_id: this.checkBrand,
                store_id: this.checkCompany,
                key_word: this.name
            }).then(Er => {
                if (Er.return_code === 200) {
                    this.tableData = Er.data
                }
            })
        },

        //获取添加岗位信息
        toAddStation() {
            this.dialogType = 1
            this.addStationInfo()
        },

        //获取企微用户列表
        addStationInfo() {
            getAddStationInfo({
                brand_id: this.checkBrand,
                store_id: this.checkCompany,
                station_name: this.stationName,
            }).then(Er => {
                if (Er.return_code === 200) {
                    this.treeData = Er.data.department_list
                    this.showDialog = true
                }
            })
        },


        //展开焦点
        showFocus() {
            let treeDate = JSON.parse(JSON.stringify(this.treeData))
            if (this.staffName.trim().length === 0) {
                return false
            }

            let newArr = this.treeConvertToArr(treeDate)
            newArr.some(item => {
                if (item.name.indexOf(this.staffName) > -1) {
                    this.expanded.push(item.parentid)
                }
            })
        },

        //转一位数组
        treeConvertToArr(arr) {
            let arrs = [];
            let result = [];
            arrs = arrs.concat(arr);
            while (arrs.length) {
                let first = arrs.shift();
                if (first.children) {
                    //如果有子集
                    arrs = arrs.concat(first.children);
                    delete first["children"];
                }
                result.push(first);
            }
            return result;
        },

        //删除岗位
        delStation: function (id) {
            this.$confirm('是否删除该岗位', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                delAddStationInfo({
                    station_id: id,
                }).then(Er => {
                    if (Er.return_code === 200) {
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                        this.getStationList()
                    }
                })

            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },

        //新增按钮
        addQeury: function () {
            let user_id_array = [];
            this.checkStaff.map(item => {
                user_id_array.push(item.id)
            })
            if (this.dialogType === 1) {//新增
                saveAddStationInfo({
                    brand_id: this.checkBrand,
                    store_id: this.checkCompany,
                    name: this.stationName,
                    user_ids: user_id_array.join(',')
                }).then(Er => {
                    if (Er.return_code === 200) {
                        this.showDialog = false;
                        this.stationName = ''
                        this.staffName = '';
                        this.checkStaff = [];
                        this.dialogType = 1;
                        this.expanded = [];
                        this.$message({
                            type: 'success',
                            message: Er.return_msg
                        });
                        this.getStationList()
                    }
                })
            } else {
                editAddStationInfo({
                    brand_id: this.checkBrand,
                    store_id: this.checkCompany,
                    name: this.stationName,
                    station_id: this.stationId,
                    user_ids: user_id_array.join(',')
                }).then(Er => {
                    if (Er.return_code === 200) {
                        this.showDialog = false;
                        this.stationName = ''
                        this.staffName = '';
                        this.checkStaff = [];
                        this.dialogType = 1;
                        this.expanded = [];
                        this.$message({
                            type: 'success',
                            message: Er.return_msg
                        });
                        this.getStationList()
                    }
                })
            }
        },

        //树状节点选择事件
        treeCheck: function (nowNode, checkNode) {
            console.log(checkNode, 'checkNode')
            let arr = checkNode.checkedNodes, newarr = [];
            arr.map(item => {
                if (!item.children || item.children.length == 0) {
                    newarr.push(item)
                }
            })
            this.checkStaff = newarr;
        },

        //移除选择的员工
        removeCheck: function (num) {
            console.log(this.checkStaff[num])
            this.checkStaff.splice(num, 1);
            this.$refs.staffTree.setCheckedNodes(this.checkStaff);
        },


        //编辑岗位
        editStation: function (val) {
            this.dialogType = 2;//编辑类型
            this.stationName = val.name//岗位名称
            this.stationId = val.id//岗位名称
            this.addStationInfo();//获取企微列表
        },

        //关闭弹窗
        closeDialog: function () {
            this.stationName = ''
            this.staffName = '';
            this.checkStaff = [];
            this.showDialog = false;
            this.dialogType = 1;
        }
    }
}
</script>
<style scoped>
.stationManage {
    min-height: 100%;
}

.title-page {
    font-size: 30px;
    color: #3e566e;
    padding-top: 40px;
    padding-bottom: 30px;
}

.content-station {
    padding: 0 40px;
    background-color: #ffffff;
    box-shadow: 0px 10px 20px 0px rgba(62, 86, 110, 0.06);
    border-radius: 6px;
}

.station-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.name-input {
    width: 380px;
}

.station-info {
    padding: 40px 0;
}

.station-info /deep/ .el-input-group__append, .bdr-left /deep/ .el-input-group__append {
    border-left: 1px solid #ffffff;
    background-color: #ffffff;
    margin-left: -1px;
}

.station-input {
    flex: 1;
    margin-left: 20px;
}

.stationManage /deep/ .el-dialog__footer {
    text-align: center;
}

.gl-p {
    margin: 30px 0 20px 0;
    font-size: 14px;
    color: #3E566E;
}

.bdr-yg {
    height: 460px;
    border: 1px solid rgba(62, 86, 110, 0.2);
    border-radius: 6px;
}

.bdr-left, .bdr-right {
    height: 100%;
}

.bdr-left {
    border-right: 1px solid rgba(62, 86, 110, 0.1);
}

.bdr-right {
    flex: 1;
    padding: 20px 30px;
    box-sizing: border-box;
}

.left-yg {
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #3E566E;
    margin: 20px;
}

.check-title {
    font-size: 14px;
    color: rgba(62, 86, 110, 0.6);
    margin-bottom: 50px;
}

.el-icon-error {
    color: #DC2718;
    margin-left: 10px;
}

.el-icon-error:hover {
    cursor: pointer;
}

.grid-check {
    color: #3E566E;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 20px;
}
</style>